import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from '../redux/userSlice';
import styled from 'styled-components';
import { BasicButton } from '../utils/buttonStyles';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import image from '../assets/image.png'
import './viewProduct.css'
import { getProductDetails, updateStuff } from '../redux/userHandle';
import { Avatar, Card, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { generateRandomColor, timeAgo } from '../utils/helperFunctions';
import { MoreVert } from '@mui/icons-material';

const ViewProduct = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const productID = params.id;


    const { currentUser, currentRole, productDetails, loading, responseDetails } = useSelector(state => state.user);

    useEffect(() => {
        dispatch(getProductDetails(productID));
    }, [productID, dispatch]);


    const [anchorElMenu, setAnchorElMenu] = useState(null);

    const handleOpenMenu = (event) => {
        setAnchorElMenu(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorElMenu(null);
    };

    const deleteHandler = (reviewId) => {
        const fields = { reviewId };

        dispatch(updateStuff(fields, productID, "deleteProductReview"));
    };

    const reviewer = currentUser && currentUser._id

    return (
        <>
            {loading ?
                <div>Loading...</div>
                :
                <>
                    {
                        responseDetails ?
                            <div>Product not found</div>
                            :
                            <>
                                {/* <ProductContainer>
                                    <ProductImage src={productDetails && productDetails.productImage} alt={productDetails && productDetails.productName} />
                                    <ProductInfo>
                                        <ProductName>{productDetails && productDetails.productName}</ProductName>
                                        <PriceContainer>
                                            <PriceCost>Rs{productDetails && productDetails.price && productDetails.price.cost}</PriceCost>
                                            <PriceMrp>Rs{productDetails && productDetails.price && productDetails.price.mrp}</PriceMrp>
                                            <PriceDiscount>{productDetails && productDetails.price && productDetails.price.discountPercent}% off</PriceDiscount>
                                        </PriceContainer>
                                        <Description>{productDetails && productDetails.description}</Description>
                                        <ProductDetails>
                                            <p>Category: {productDetails && productDetails.category}</p>
                                            <p>Subcategory: {productDetails && productDetails.subcategory}</p>
                                        </ProductDetails>
                                    </ProductInfo>
                                </ProductContainer> */}
                                {/* <ProductContainer>
                                <Card sx={{ width: 445 }}>
                                    <CardMedia
                                        component="img"
                                        height="440"
                                        image={productDetails && productDetails.productImage} alt={productDetails && productDetails.productName}
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                        {productDetails && productDetails.productName}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                           {productDetails && productDetails.description}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <PriceContainer>
                                            <PriceCost>Rs{productDetails && productDetails.price && productDetails.price.cost}</PriceCost>
                                            <PriceMrp>Rs{productDetails && productDetails.price && productDetails.price.mrp}</PriceMrp>
                                            <PriceDiscount>{productDetails && productDetails.price && productDetails.price.discountPercent}% off</PriceDiscount>
                                        </PriceContainer>
                                    </CardActions>
                                    <ProductDetails>
                                            <p>School Name: {productDetails && productDetails.category}</p>
                                            <p>Class Name: {productDetails && productDetails.subcategory}</p>
                                        </ProductDetails>
                                </Card>
                                </ProductContainer> */}
                                <main className="main">
                                    {/* Wrapper Section */}
                                    <section className="section wrapper wrapper-section">
                                    <div className="container wrapper-column">
                                        <div className="wrapper-bgimage">
                                        {/* <img
                                            src={productDetails && productDetails.productImage} alt={productDetails && productDetails.productName}
                                            className="wrapper-image"
                                        /> */}
                                        <img
                                            src={image} alt={productDetails && productDetails.productName}
                                            className="wrapper-image"
                                        />
                                        </div>
                                        <div className="wrapper-content">
                                        <div className="wrapper-inform">
                                            <span className="badge badge-darken">{productDetails && productDetails.category}</span>
                                            <h1 className="display-medium font-bold">{productDetails && productDetails.productName}</h1>
                                            {/* <p className="text-base font-normal">
                                                {productDetails && productDetails.description}
                                            </p> */}
                                            <p className="text-base font-normal">
                                                Author: {productDetails && productDetails.tagline}
                                            </p>
                                        </div>
                                        <div className="wrapper-detail">
                                            <div className="price">
                                                {/* <span className="text-base font-medium">{productDetails && productDetails.productName}</span> */}
                                                <h3 className="text-large font-semi">Rs{productDetails && productDetails.price && productDetails.price.cost}</h3>
                                            </div>
                                        </div>
                                        <ProductDetails>
                                            <p>Book Category: {productDetails && productDetails.category}</p>
                                            {/* <p>Class Name: {productDetails && productDetails.subcategory}</p> */}
                                        </ProductDetails>
                                        </div>
                                    </div>
                                    </section>
                                </main>

                                {
                                    currentRole === "Customer" &&
                                    <>
                                        <ButtonContainer>
                                            <BasicButton
                                                onClick={() => dispatch(addToCart(productDetails))}
                                            >
                                                Add to Cart
                                            </BasicButton>
                                        </ButtonContainer>
                                        <br /><br /><br /><br />
                                    </>
                                }
                                {/* <ReviewWritingContainer>
                                    <Typography variant="h4">Reviews</Typography>
                                </ReviewWritingContainer> */}

                                {/* {productDetails.reviews && productDetails.reviews.length > 0 ? (
                                <ReviewContainer>
                                    {productDetails.reviews.map((review, index) => (
                                        <ReviewCard key={index}>
                                            <ReviewCardDivision>
                                                <Avatar sx={{ width: "60px", height: "60px", marginRight: "1rem", backgroundColor: generateRandomColor(review._id) }}>
                                                    {review.reviewer ? String(review.reviewer.name).charAt(0) : "A"}
                                                </Avatar>
                                                <ReviewDetails>
                                                    <Typography variant="h6">{review.reviewer ? review.reviewer.name : "Anonymous"}</Typography>
                                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                                                        <Typography variant="body2">
                                                            {timeAgo(review.date)}
                                                        </Typography>
                                                    </div>
                                                    <Typography variant="subtitle1">Rating: {review.rating}</Typography>
                                                    <Typography variant="body1">{review.comment}</Typography>
                                                </ReviewDetails>
                                                {review.reviewer && review.reviewer._id === reviewer &&
                                                    <>
                                                        <IconButton onClick={handleOpenMenu} sx={{ width: "4rem", color: 'inherit', p: 0 }}>
                                                            <MoreVert sx={{ fontSize: "2rem" }} />
                                                        </IconButton>
                                                        <Menu
                                                            id="menu-appbar"
                                                            anchorEl={anchorElMenu}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'left',
                                                            }}
                                                            keepMounted
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                            open={Boolean(anchorElMenu)}
                                                            onClose={handleCloseMenu}
                                                            onClick={handleCloseMenu}
                                                        >
                                                            <MenuItem onClick={() => {
                                                                handleCloseMenu()
                                                            }}>
                                                                <Typography textAlign="center">Edit</Typography>
                                                            </MenuItem>
                                                            <MenuItem onClick={() => {
                                                                deleteHandler(review._id)
                                                                handleCloseMenu()
                                                            }}>
                                                                <Typography textAlign="center">Delete</Typography>
                                                            </MenuItem>
                                                        </Menu>
                                                    </>
                                                }
                                            </ReviewCardDivision>
                                        </ReviewCard>
                                    ))}
                                </ReviewContainer>)
                                    :
                                    <ReviewWritingContainer>
                                        <Typography variant="h6">No Reviews Found. Add a review.</Typography>
                                    </ReviewWritingContainer>
                                }    */}
                            </>
                    }
                </>
            }
        </>
    );
};

export default ViewProduct;

const ProductContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px;
    justify-content: center;
    align-items: center;
    @media (min-width: 768px) {
        flex-direction: row;
    }
`;
// const ProductImage = styled.img`
//     max-width: 300px;
//     /* width: 50%; */
//     margin-bottom: 20px;
// `;

const PriceContainer = styled.div`
    display: flex;
    gap: 8px;
    margin-top: 8px;
    margin-left: 8px;
`;

const PriceMrp = styled.p`
    margin-top: 8px;
    text-decoration: line-through;
    color: #525050;
`;

const PriceCost = styled.h3`
    margin-top: 8px;
`;

const PriceDiscount = styled.p`
    margin-top: 8px;
    color: darkgreen;
`;

const ProductDetails = styled.div`
    margin: 16px;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ReviewWritingContainer = styled.div`
    margin: 6rem;
    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    flex-direction:column;
`;

const ReviewContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
`;

const ReviewCard = styled(Card)`
  && {
    background-color: white;
    margin-bottom: 2rem;
    padding: 1rem;
  }
`;

const ReviewCardDivision = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const ReviewDetails = styled.div`
  flex: 1;
`;