import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCustomers } from '../../../redux/userHandle.js';
import { Typography, Box, Card, Divider } from '@mui/material';
import { IndigoButton } from '../../../utils/buttonStyles';

const ShowOrders = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const customerID = params.id;

    useEffect(() => {
        dispatch(getCustomers(customerID, "getOrderedProductsByCustomer"));
    }, [customerID, dispatch]);

    const { loading, customersList, responseCustomersList } = useSelector(state => state.user);

    return (
        <>
            {loading ? (
                <h1>Loading...</h1>
            ) : (
                <>
                    {responseCustomersList ? (
                        <h1>No Orders Found</h1>
                    ) : (
                        <>
                            <Typography variant="h5" gutterBottom>
                                Orders List:
                            </Typography>
                            {/* Mapping through each customer order */}
                            {Array.isArray(customersList) && customersList.length > 0 ? (
                                customersList.map((order) => (
                                    <Box key={order._id} mb={4}> {/* Adds margin between each order */}
                                        <Card sx={{ padding: '20px', marginBottom: '20px' }}>
                                            {/* Order Number */}
                                            <Typography variant="h6" gutterBottom>
                                                Order Number: {order.orderNumber}
                                            </Typography>

                                            {/* Product List */}
                                            <Typography variant="body1">
                                                Products:
                                            </Typography>
                                            {order.orderedProducts.map((product) => (
                                                <CardBox key={product._id}>
                                                    <Typography variant="body1">
                                                        {product.productName} 
                                                    </Typography>
                                                </CardBox>
                                            ))}

                                            {/* Other Order Details */}
                                            <Typography variant="body1" color="textSecondary" gutterBottom>
                                                Ordered At: {new Date(order.paidAt).toLocaleString()}
                                            </Typography>
                                            <Typography variant="body1" color="textSecondary" gutterBottom>
                                                Phone Number: {order.shippingData.phoneNo}
                                            </Typography>
                                            <Typography variant="body1" color="textSecondary" gutterBottom>
                                                Shipping Address: {order.shippingData.address}, {order.shippingData.city}, {order.shippingData.state}, {order.shippingData.pinCode}
                                            </Typography>

                                            <Typography variant="body1" color="textSecondary">
                                                Total Cost: Rs {order.orderedProducts.reduce((acc, product) => acc + product.price.cost, 0) + 100} {/* Additional charge */}
                                            </Typography>
                                        </Card>

                                        {/* Divider to visually separate each order */}
                                        <Divider />
                                    </Box>
                                ))
                            ) : (
                                <Typography variant="h6">No Orders Found</Typography>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default ShowOrders;

// Styled component for each product box
const CardBox = ({ children }) => {
    return (
        <Card 
            sx={{ 
                padding: '10px', 
                margin: '10px 0', 
                display: 'flex', 
                fontWeight: 'bold',
                justifyContent: 'center', 
                alignItems: 'center', 
                backgroundColor: '#f5f5f5',
                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' 
            }}
        >
            {children}
        </Card>
    );
};
