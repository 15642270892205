import { useDispatch, useSelector } from "react-redux";
import { getSpecificProducts } from "../../../redux/userHandle"; // Assuming `markOrderAsCompleted` and `markOrderAsDelivered` are handled in the backend.
import { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { BlueButton, GreenButton } from "../../../utils/buttonStyles";
import TableTemplate from "../../../components/TableTemplate";
import { useNavigate } from "react-router-dom";

const OutForDeliverySection = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { currentUser, specificProductData, responseSpecificProducts } = useSelector(state => state.user);

    useEffect(() => {
        dispatch(getSpecificProducts(currentUser._id, "getOrderedProductsBySeller"));
    }, [dispatch, currentUser._id]);

    // Handle marking an order as completed
    const handleMarkAsCompleted = async (orderId) => {
        try {
            const response = await fetch(`https://backend.alamkitab.com/${orderId}/complete`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to mark the order as completed');
            }

            const data = await response.json();
            alert(data.message);

            // Re-fetch or update order list after marking as completed
            dispatch(getSpecificProducts(currentUser._id, "getOrderedProductsBySeller"));
        } catch (error) {
            console.error('Error marking order as completed:', error);
        }
    };

    // Handle marking an order as delivered
    const handleMarkAsDelivered = async (orderId) => {
        try {
            const response = await fetch(`https://backend.alamkitab.com/${orderId}/delivered`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to mark the order as delivered');
            }

            const data = await response.json();
            alert(data.message);

            // Re-fetch or update order list after marking as delivered
            dispatch(getSpecificProducts(currentUser._id, "getOrderedProductsBySeller"));
        } catch (error) {
            console.error('Error marking order as delivered:', error);
        }
    };

    const productsColumns = [
        { id: 'orderNumber', label: 'Order Number', minWidth: 100 },
        { id: 'totalPrice', label: 'Total Price', minWidth: 100 },
        { id: 'category', label: 'Category', minWidth: 100 },
        { id: 'status', label: 'Order Status', minWidth: 150 },
        { id: 'paidAt', label: 'Ordered At', minWidth: 200 }
    ];

    const productsRows = Array.isArray(specificProductData) && specificProductData.length > 0
        ? specificProductData.map((order) => ({
            orderNumber: order.orderNumber,
            productName: order.orderedProducts[0]?.productName || "N/A",
            quantity: order.orderedProducts[0]?.quantity || 0,
            totalPrice: order.totalPrice + 100 || 0,
            category: order.orderedProducts[0]?.category || "N/A",
            status: order.orderStatus,
            paidAt: new Date(order.paidAt).toLocaleString(),
            id: order._id,
            customerId: order.buyer
        }))
        : [];

    const ProductsButtonHaver = ({ row }) => {
        return (
            <>
                <BlueButton
                    onClick={() => {
                        navigate("/Seller/orders/customers/" + row.customerId);
                    }}
                >
                    View Order
                </BlueButton>
                
                {/* Completed Button only shows if status is not "Completed" or "Delivered" */}
                {row.status !== "Completed" && row.status !== "Delivered" && (
                    <GreenButton
                        onClick={() => handleMarkAsCompleted(row.id)}
                    >
                        Completed
                    </GreenButton>
                )}

                {/* Delivered Button shows if status is "Completed" but not "Delivered" */}
                {row.status === "Completed" && row.status !== "Delivered" && (
                    <GreenButton
                        onClick={() => handleMarkAsDelivered(row.id)}
                    >
                        Delivered
                    </GreenButton>
                )}
            </>
        );
    };

    return (
        <>
            {responseSpecificProducts ? (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                    <GreenButton
                        variant="contained"
                        onClick={() => navigate("/Seller/addproduct")}
                    >
                        Add Products
                    </GreenButton>
                </Box>
            ) : (
                <>
                    <Typography variant="h5" gutterBottom>
                        Orders List:
                    </Typography>

                    <TableTemplate buttonHaver={ProductsButtonHaver} columns={productsColumns} rows={productsRows} />
                </>
            )}
        </>
    );
};

export default OutForDeliverySection;
