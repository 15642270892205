import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const OrderTracking = () => {
    const { orderNumber } = useParams();
    const [order, setOrder] = useState(null);

    useEffect(() => {
        const fetchOrder = async () => {
            try {
                const response = await axios.get(`/api/orders/track/${orderNumber}`);
                setOrder(response.data.order);
            } catch (error) {
                console.error('Error fetching order:', error);
            }
        };

        fetchOrder();
    }, [orderNumber]);

    return (
        <div>
            {order ? (
                <div>
                    <h1>Order Details</h1>
                    <p>Order Number: {order.orderNumber}</p>
                    {/* Display other order details */}
                </div>
            ) : (
                <p>Loading order details...</p>
            )}
        </div>
    );
};

export default OrderTracking;
