import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
// import Grid from '@mui/material/Grid';yyy
import { Box, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { addStuff } from '../../../redux/userHandle';
import { useNavigate, useParams } from 'react-router-dom';
import Popup from '../../../components/Popup';
import { fetchProductDetailsFromCart, removeAllFromCart, removeSpecificProduct } from '../../../redux/userSlice';
import { BlueButton, GreenButton } from '../../../utils/buttonStyles';

const PaymentForm = ({ handleBack }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { status, currentUser, productDetailsCart } = useSelector(state => state.user);
    const params = useParams();
    const productID = params.id;

    const [showPopup, setShowPopup] = useState(false);
    const [message, setMessage] = useState("");

    useEffect(() => {
        if (productID) {
            dispatch(fetchProductDetailsFromCart(productID));
        }
    }, [productID, dispatch]);

    const singleProductQuantity = productDetailsCart && productDetailsCart.quantity;
    const totalsingleProductPrice = productDetailsCart && productDetailsCart.price && productDetailsCart.price.cost * productDetailsCart.quantity;

    const multiOrderData = {
        buyer: currentUser._id,
        shippingData: currentUser.shippingData,
        orderedProducts: currentUser.cartDetails,
        paymentInfo: { method: "Cash on Delivery" }, // Update paymentInfo to include method
        productsQuantity: currentUser.cartDetails.reduce((total, item) => total + item.quantity, 0),
        totalPrice: currentUser.cartDetails.reduce((total, item) => total + (item.quantity * item.price.cost), 0),
    };

    const singleOrderData = {
        buyer: currentUser._id,
        shippingData: currentUser.shippingData,
        orderedProducts: productDetailsCart,
        paymentInfo: { method: "Cash on Delivery" }, // Update paymentInfo to include method
        productsQuantity: singleProductQuantity,
        totalPrice: totalsingleProductPrice,
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (productID) {
                dispatch(addStuff("newOrder", singleOrderData));
                dispatch(removeSpecificProduct(productID));
            } else {
                dispatch(addStuff("newOrder", multiOrderData));
                dispatch(removeAllFromCart());
            }
            // If everything went smoothly, navigate to the next page
            navigate('/Aftermath');
        } catch (error) {
            // Handle any errors that occurred during the dispatch process
            console.error("Error placing order:", error);
            // Optionally, dispatch an action to update the Redux state with the error status
        }
    };
    

    useEffect(() => {
        if (status === 'added') {
            navigate('/Aftermath');
        } else if (status === 'failed') {
            setMessage("Order Failed");
            setShowPopup(true);
        } else if (status === 'error') {
            setMessage("Network Error");
            setShowPopup(true);
        }
    }, [status, navigate]);

    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Payment method: Cash on Delivery
            </Typography>
            <form onSubmit={handleSubmit}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <BlueButton onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                        Back
                    </BlueButton>
                    <GreenButton
                        variant="contained"
                        type='submit'
                        sx={{ mt: 3, ml: 1 }}
                    >
                        Place order
                    </GreenButton>
                </Box>
            </form>
            <Popup message={message} setShowPopup={setShowPopup} showPopup={showPopup} />
        </React.Fragment>
    );
};

export default PaymentForm;
