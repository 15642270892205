import React, { useEffect, useState } from 'react';
import { Box, colors, Container, styled } from '@mui/material';
import Slide from './Slide';
import Banner from './Banner';
import './cssforstyling.css';
import { useDispatch, useSelector } from 'react-redux';
import { getProducts } from '../redux/userHandle.js';
import ProductsMenu from './customer/components/ProductsMenu';
import { NewtonsCradle } from '@uiball/loaders';
import { Link } from 'react-router-dom';
import Search from './customer/components/Search.jsx';

const Home = () => {
  // const adURL =
  //   'https://picsum.photos/536/354';

  const dispatch = useDispatch();

  const { productData, responseProducts, error } = useSelector((state) => state.user);

  const [showNetworkError, setShowNetworkError] = useState(false);

  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      const timeoutId = setTimeout(() => {
        setShowNetworkError(true);
      }, 40000);
      // console.log(error.error);

      return () => clearTimeout(timeoutId);
    }
  }, [error]);

  return (
    <div id="top">
      <Container
        sx={{
          display: 'none',
          '@media (max-width: 600px)': {
            display: 'flex',
          },
        }}
      >
        {/* <ProductsMenu dropName="School Name" />
        <ProductsMenu dropName="Class" /> */}
      </Container>
      <BannerBox className='BannerBox'>
        {/* <Banner /> */}
        <h1 className='h1tag'>Search the books you want</h1><br />
        <Box sx={{ flexGrow: 3, display: { md: 'flex' }, }}>
          <Search /><br />
          <ProductsMenu dropName="Explore Subjects" />
          {/* <ProductsMenu dropName="Class" /> */}
        </Box><br /><br />
      </BannerBox>


      {showNetworkError ? (
        <StyledContainer>
          <h1>Sorry Network Issue.</h1>
        </StyledContainer>
      ) : error ? (
        <StyledContainer>
          <h1>Please Wait A Second</h1>
          <NewtonsCradle size={70} speed={1.4} color="black" />
        </StyledContainer>
      ) : (
        <>
          {responseProducts ? (
            <>
              <StyledContainer>No products found right now</StyledContainer>
              <StyledContainer>
                Become a seller to add products
                <Link to={"/Sellerregister"}>
                  Join
                </Link>
              </StyledContainer>
            </>
          ) : (
            <>
              <Component>
                {/* <LeftComponent> 
                  <Slide products={productData} title="Order Books" />
                </LeftComponent> */}

                {/* <RightComponent>
                  <img src={adURL} alt="" style={{ width: 217 }} />
                </RightComponent> */}
              </Component>

              {/* <Slide products={productData} title="Deals of the Day" /> */}
              {/* <Slide products={productData} title="Suggested Items" />
              <Slide products={productData} title="Discounts for You" />
              <Slide products={productData} title="Recommended Items" /> */}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Home;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;
`;

const BannerBox = styled(Box)`
  background: darkgreen;
`;

const Component = styled(Box)`
  display: flex;
`;

const LeftComponent = styled(Box)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

